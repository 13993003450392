import axios                     from '@/api/api';
import { AddressType }           from '@/types/address';
import { CountryResponse }       from '@/types/country';
import { CountryStatesResponse } from '@/types/countryState';
import getRoute                  from '@/utils/routing';

class CountryApi {
    async getCountriesByAddressType(type: AddressType) {
        return axios.get<CountryResponse>(
            getRoute(
                'api_v1_countries_get_countries',
                { type },
            ),
        );
    }

    async getCountriesForDeliveryAddresses() {
        return this.getCountriesByAddressType(AddressType.DELIVERY);
    }

    async getCountriesForInvoiceAddresses() {
        return this.getCountriesByAddressType(AddressType.INVOICE);
    }

    async getCountryStates() {
        return axios.get<CountryStatesResponse>(
            getRoute('api_v1_country_states_get_all_by_shop'),
        );
    }
}

export default (new CountryApi());
